import React from "react";
import { client } from "../client";
import Layout from "layouts/main";
import { useFeaturedPost, useMenu, useGeneralOptionsSettings } from "hooks";
import { FeaturedPost, CEOLink, JobListingsTable } from "components";
import Image from "next/image";
import Link from "next/link";

export default function Page(): JSX.Element {
  const { useQuery } = client;

  const featuredPost = useFeaturedPost();
  const headerLinks = useMenu("PRIMARY");
  const footerLinks = useMenu("FOOTER");
  const options = useGeneralOptionsSettings();
  const ceoLink = options?.generalSettings?.ceoLink;
  const jobs = useQuery().jobs().nodes;

  return (
    <Layout page={null} headerLinks={headerLinks} footerLinks={footerLinks}>
      <div className="content-container m-auto grid grid-cols-2 w-full max-w-full overflow-hidden">
        <div className="flex-1 flex flex-col justify-center">
          <h1 className="mb-4">404</h1>
          <h3 className="font-bold mb-10">Ground Control to Major Tom</h3>
          <div className="mb-10 text-xl">
            Your circuit&apos;s dead, there&apos;s something wrong.
          </div>

          <div className="w-32">
            <Link href="/">
              <a className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-display font-bold text-white bg-black hover:bg-slate-600 hover:cursor-pointer focus:outline-0 focus:ring-0">
                Go Home
              </a>
            </Link>
          </div>
        </div>
        <div>
          <Image
            className="flex-1 illustration md:ml-12"
            src="/images/error.png"
            alt="service-img"
            width={1450}
            height={1000}
            layout="responsive"
          ></Image>
        </div>
      </div>
      <JobListingsTable jobs={jobs} />
      <FeaturedPost post={featuredPost} />

      <CEOLink
        className="h-[70px] w-[271px] fixed bottom-5 right-5 z-50"
        link={ceoLink}
      />
    </Layout>
  );
}
